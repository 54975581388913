import React, { useEffect, useState } from 'react';
import { useSelector } from '@hooks/redux';
import LazySVG from '@components/LazySvg';
import clsx from '@utility/clsx';
import ButtonV2 from './ButtonV2';
import { getBrandPageUrl,getBycPageUrl } from '@utility/Api';
import { fixL1LetterCase, getL1Type } from '@utility/levelUtility';
import { USER_URLS } from '@components/link-utils';
import { isBycEnabled } from '@utility/userUtility';
import { useLocation } from 'react-router-dom';
import { EBuildYourCareerLevelsL0, formatProgramForCorrectUrl } from '@utility/buildYourCareerUtility';

class Props {
  className?: string = '';
  variant?: '' | 'large';
  l0Id?: string;
  l1Id?: string;
  l2Id?: string;
  showL1?: boolean;
  isAtolL0?: boolean;
}

export const LevelBreadcrumb = ({
  className = '',
  variant = '',
  l0Id,
  l1Id,
  l2Id,
  showL1 = true,
  isAtolL0 = false,
}: Props): React.ReactElement => {
  const labelL0Map = useSelector(state => state.utils.labelL0Map);
  const labelL1Map = useSelector(state => state.utils.labelL1Map);
  const labelL2Map = useSelector(state => state.utils.labelL2Map);
  const l1Map = useSelector(state => state.utils.l1Map);

  const [l0, setL0] = useState(null);
  const [l1, setL1] = useState(null);
  const [l2, setL2] = useState(null);

  const location = useLocation();
  const userProfile = useSelector(state => state.user.userProfile)
  const isBycUser = userProfile && isBycEnabled(userProfile)

  useEffect(() => {
    if (l0Id) {
      //L0
      const l0Obj = labelL0Map[l0Id];
      setL0({ ...l0Obj });
    }

    if (l1Id) {
      //L1
      l1Id = fixL1LetterCase(l1Id);
      const l1Obj = labelL1Map[l1Id];

      if (showL1) {
        // HANDLE BuildYourCareer
        if(isBycUser && location.pathname.includes('build-your-career')) {
          setL1({ ...l1Obj, url: handleL1UrlForCareerProgramPage(l1Obj) });
        } else {
          setL1({ ...l1Obj, url: getBrandPageUrl(getL1Type(l1Id), l1Id) });
        }
      }

      if (!l0Id) {
        //derive L0 from L1
        const derivedL0Id = l1Map[l1Id]?.l0?.key;
        const l0Obj = labelL0Map[derivedL0Id];
        setL0({ ...l0Obj });
      }
    }

    if (l2Id) {
      //L2
      const l2Obj = labelL2Map[l2Id];
      const urlL2 = handleL2Url()
      setL2({ ...l2Obj, url: urlL2 });
    }
  }, [l0Id, l1Id, l2Id, labelL0Map, labelL1Map, labelL2Map, l1Map]);

  // HANDLE the URL for the L1 breadcrumb link
  const handleL1UrlForCareerProgramPage = (l1) => {
    // IF is a CareerProgram
    if(l1 && l1.l0 && l1.l0.key === EBuildYourCareerLevelsL0.career_career_programs) {
      return USER_URLS.ROLE_SPECIFIC_PROGRAM.URL.replace(":roleId",formatProgramForCorrectUrl(l1?.key))
    }
    return ""
  }
  // HANDLE the URL for the L2 breadcrumb link
  const handleL2Url = () => {
    // IF is a L2 from CareerPrograms OR UpskillPrograms
    if(isBycUser && location.pathname.includes('build-your-career')) {
      if(l0Id 
        && (l0Id === EBuildYourCareerLevelsL0.career_career_programs
        || l0Id === EBuildYourCareerLevelsL0.career_upskill_programs)
      ) {
        return ""
      } 
    } else {
      return USER_URLS.BRAND_L2_PAGE.URL
        .replace(':category', getL1Type(l1Id))
        .replace(':brand', l1Id)
        .replace(':l2Id', l2Id)
    }
  }

  return (
    <section
      className={clsx('level-breadcrumb', {
        [className]: !!className,
        ['level-breadcrumb__' + variant]: !!variant,
      })}
    >
      {l0 && !isAtolL0 && (
        <ButtonV2 variant="text-btn-no-arrow" small disabled to="">
          {l0?.label}
        </ButtonV2>
      )}

      {l0 && l1 && (
        <LazySVG
          className="level-breadcrumb__arrow"
          src="/images/svg-icons/level-breadcrumb-arrow.svg"
          alt=""
        />
      )}
      {l1 && l2 && l1.label === l2.label ? (
        <ButtonV2 
          variant="text-btn-no-arrow" 
          small 
          disabled={l1?.url === ""}
          to={l1.url ? l1.url : ""}
        >
          {l1?.label}
        </ButtonV2>
      ) : (
        <>
          {l1 && (
            <ButtonV2 
              variant="text-btn-no-arrow" 
              small 
              disabled={l1?.url === ""}
              to={l1.url ? l1.url : ""}
            >
                {l1?.label}
            </ButtonV2>
          )}

          {l1 && l2 && (
            <LazySVG
              className="level-breadcrumb__arrow"
              src="/images/svg-icons/level-breadcrumb-arrow.svg"
              alt=""
            />
          )}

          {l2 && (
            <ButtonV2 
              variant="text-btn-no-arrow" 
              small 
              disabled={l2?.url === ""}
              to={l2.url ? l2.url : ""}
            >
                {l2?.label}
            </ButtonV2>
          )}
        </>
      )}
    </section>
  );
};

export default LevelBreadcrumb;
