import React, { Component } from 'react';
import * as actions from '../store/actions';
import { connect } from 'react-redux';
import SettingsIcon from '@svg-icons/settings.svg';
import { courseStatus, SORTBY, DURATION, FILTER_OTHERS, FILTER_COURSE, sessionStatus } from '../utility/const';
import CoursesResults from '@components/CoursesResults';
import clsx from '@utility/clsx';
import {
  fromCamelToCapitalize,
  isVirtualClassroom,
  isLearningPath,
  checkIfObjHasValues,
  isMandatory,
  getDurationLabel,
  getRangeScheduleByDateString,
  getScheduleLabel,
  isNew,
  isBookedCourse,
  isCompletedCourse,
  getSubtitlesCodes,
  isStartedCourse,
  isMissedCourse,
  getTrainerFullNameByUsername,
  isCourseExpiring,
  isLivestreamCourse,
  getUserFromTrainer,
  getExpirationDateFilterKey,
  getDaysAgo,
  printDate,
  getDaysInTheFuture,
  getTodayMidnight,
  isCourseToBeStarted,
} from '@utility/Api';
import { withRouter } from 'react-router-dom';
import CardClassroomLoader from '@components/UI/CardClassroomLoader';
import {
  sortCourses,
  sortDurationFilters,
  sortExpirations,
  sortScheduleFilters,
  sortStatusFilters,
  sortTrainers,
} from '../utility/sortUtility';
import { createFilterEnableMap, updateFilterMaps } from './filter-utils';
import { CourseToFilterMap, FilterEnableMap, FilterToCourseMap } from '../model/FilterMap';
import FilterModal from './FilterModal';
import Chip from './UI/Chip';
import Select from '@components/UI/Select';
import {
  filterByDurations,
  filterByLanguage,
  filterByLocations,
  filterBySchedules,
  filterBySections,
  filterByTeachers,
  filterByRecordingAvailable,
  filterByCertificateAvailable,
  filterByEl360Courses,
  respectDuration,
  filterByPrice,
  filterByExpiration,
  filterByOfferPlan,
  filterByDateSessionRange,
  getDates,
  getFixedRange,
  filterByDateFixedRange,
  areMinMaxValuedInDateSessionRange,
  isDateSessionRangeFilterApplied,
  DATE_CUSTOM_RANGE,
  DATES_FIXED_RANGE,
  filterByChannels,
  filterByComplexities,
  combinateComplexityCategory,
  getComplexityFromComplexityFilter,
} from '@utility/filterUtility';
import {
  catalogParamKeysMapping,
  isBrandCarouselPage,
  isBrandL2Page,
  isProfileCertificates,
  isProfileProgressOrCertificates,
  isSearchPage,
  USER_URLS,
} from '@components/link-utils';
import { isBrandSubcataloguePage } from './link-utils';
import { getAllLpCoursesId } from '@utility/LPUtility';
import { Trainer } from '@model/CoursesClass';
import queryString from 'query-string';
import { getLivestreamRecording, isLivestreamLiveNow } from '@utility/LivestreamUtility';
import {
  getPriceCourseFilter,
  getPriceLabel,
  getExpirationLabel,
  getOfferPlanLabel,
  getOfferPlanFilter,
  isPurchasable,
  getDateFixedRangeLabel,
} from '@utility/ecommerceUtility';
import { getFiltersValuesParams } from '@utility/CMSBrandUtility';
import { getVCRecording, getVCRecordingLinks } from '@utility/VCUtility';
import { useHandleBodyScrollAfterModalOpenMethod } from '@hooks/useHandleBodyScrollAfterModalOpen';
import {
  getL1BrandByCourse,
  getL1IdFromBrandId,
  getLevelLabel,
  isL1IdBrand,
} from '@utility/levelUtility';
import ButtonV2 from '@components/UI/ButtonV2';
import AnimationSlide from './animations/AnimationSlide';
import { filterByRecommendedInToDo, isCourseEl360, isCourseRecommendedInToDo } from '@utility/CourseUtility';

const {
  RATING,
  NEW,
  RELEVANCE,
  RELEVANCE_FAKE,
  SHORTEST,
  TITLE_AZ,
  TITLE_ZA,
  LEARNING_SEQUENCE,
  UPCOMING,
  COMPLETION,
  START_DATE_LIVESTREAM,
  START_DATE_LIVESTREAM_ASC,
  PRICE_DES,
  PRICE_ASC,
} = SORTBY;
export const initialOtherFilters = {
  section: {},
  brand: {},
  language: {},
  complexity: {},
  status: {},
  duration: {},
  teacher: {},
  location: {},
  schedule: {},
  others: {},
  price: {},
  offerPlan: {},
  expiration: {},
  dateFixedRange: {},
  el360: {},
};

/* const sectionObj = {
    sectionId: string,
    language: string,
    index: string,
    label: string,
}; */

const animationSlideToLeftDefault = {
  initialPosition: 'left',
  initialX: '0',
};

const animationSlideToRightDefault = {
  initialPosition: 'right',
  initialX: '0',
};

const animationXSection = '40px';
const animationDurationSection = 0.1;

export const getDefaultSortBy = (
  /*catalogueTitle,*/ areLPCourses = false /*, category*/,
  defaultSortBy,
  history
) => {
  const isInBrandL2Page = isBrandL2Page(window.location.pathname);
  const isInBrandCarouselPage = isBrandCarouselPage(window.location.pathname);
  const isInBrandSubcataloguePage = isBrandSubcataloguePage(window.location.pathname);
  let urlParams = this?.props?.urlParamsSearch || queryString.parse(window.location.search);
  const sortByParamKey = catalogParamKeysMapping['sortBy'] || 'sortBy';
  if (urlParams?.[sortByParamKey]) {
    const urlSortByValues = Array.isArray(urlParams?.[sortByParamKey])
      ? urlParams?.[sortByParamKey]
      : [urlParams?.[sortByParamKey] || ''];
    const sortByDefault = getFiltersValuesParams('sortBy', urlSortByValues)?.[0];
    if (!this?.props?.searchType) {
      delete urlParams[sortByParamKey];
    }

    history.replace(window.location.pathname + '?' + queryString.stringify(urlParams));
    if (Object.values(SORTBY).some(sort => sort === sortByDefault)) {
      return sortByDefault;
    }
  }

  if (isInBrandL2Page || isInBrandCarouselPage || isInBrandSubcataloguePage) {
    return RELEVANCE;
  }

  if (defaultSortBy) {
    return defaultSortBy;
  }

  if (areLPCourses) {
    return LEARNING_SEQUENCE;
  }

  // if (catalogueTitle === 'RECOMMENDED_TO_YOU' || category === "search") {
  //     return RECOMMANDATION;
  // }

  return NEW;
};

class FiltersV3 extends Component {
  constructor(props) {
    super(props);
    let urlParams = props?.urlParamsSearch || queryString.parse(window.location.search);
    // console.log(`urlParams`, urlParams)
    let initialAppliedFilters = {};
    let initialOtherFiltersTemp = cloneDeep(initialOtherFilters);
    let areParamsPresent = false;
    let sectionL1ToShow = [];
    let showL1Search = false;

    // console.log("SEARCH QUERY", props.searchQuery);
    // console.log("labelL1Map", props.labelL1Map);

    /* props.labelL1Map[key]?.forEach((nameL1) =>{
        if(nameL1 === props.searchQuery){
            //set true
        }
    }); */

    const excludedParams = ['sortBy'];
    // filter by params
    if (props.teacherUsernameValue) {
      initialAppliedFilters.teacher = [
        ...(initialAppliedFilters?.teacher || []),
        props.teacherUsernameValue,
      ];
      initialOtherFiltersTemp.teacher[props.teacherUsernameValue] = true;
      this.props.setTeacherUsername(null);
    }
    Object.keys(initialOtherFilters).forEach(filterKey => {
      const paramKey = catalogParamKeysMapping[filterKey] || filterKey;
      if (excludedParams.includes(paramKey)) return;
      const urlParamValuesArray = Array.isArray(urlParams?.[paramKey])
        ? urlParams?.[paramKey]
        : [urlParams?.[paramKey] || ''];
      const urlParamValues = getFiltersValuesParams(filterKey, urlParamValuesArray);
      // console.log(`urlParamValues`, urlParamValues)
      // console.log(`filterKey`, filterKey)
      urlParamValues?.forEach(value => {
        areParamsPresent = true;
        if (value && !initialAppliedFilters?.[filterKey]?.includes(value)) {
          //transform brand id into L1 id (ex. RB to brand_RB)
          if (filterKey === 'brand' && !isL1IdBrand(value)) {
            value = getL1IdFromBrandId(value);
          }

          initialAppliedFilters[filterKey] = [...(initialAppliedFilters?.[filterKey] || []), value];
          initialOtherFiltersTemp[filterKey][value] = true;
          if (!props.searchType) {
            delete urlParams[paramKey];
          }
        }
      });
    });

    if (areParamsPresent && !props.searchType) {
      //remove params from URL
      this.props.history.replace(window.location.pathname + '?' + queryString.stringify(urlParams));
    }

    this.state = {
      otherFilters: initialOtherFiltersTemp,
      appliedFilters: initialAppliedFilters,
      displayedCourses: [],
      filterOtherActive: false,
      showOther: false,
      sortBy: getDefaultSortBy(
        /*this.props.catalogueTitle,*/ this.props.areLPCourses /*, this.props.category*/,
        this.props.defaultSortBy,
        this.props.history
      ),
      limit: this.props.pagination,
      //FILTER TO BE DISPLAYED
      allOtherFilters: {
        language: [],
        status: [],
      },
      oldFilters: {},
      filterResultsCourses: [],
      filterResultsLP: [],
      arentOtherFilters: false,
      courseToFilterMap: new CourseToFilterMap(),
      filterToCourseMap: new FilterToCourseMap(),
      filterEnableMap: new FilterEnableMap(),
      dateSessionRange: null,
      sectionL1ToShow: sectionL1ToShow,
      showL1Search: showL1Search,
      bSearchPage: isSearchPage(location.pathname),
      activatedFiltersAnalytics: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    //if the pages changes
    if (this.props.isBySearchCompleted) {
      if (this.props.searchResults.length === 0) {
        this.props.history.push(USER_URLS.SUBCATALOGUE.URL);
      }
      this.props.resetSearch();
    }
    let arrayToShowL1Search = [];
    this.state.sectionL1ToShow = [];
    // console.log("PROPSSECTIONL1", this.props.sectionL1)
    if (this.props.sectionL1?.length > 0) {
      this.props.sectionL1.forEach(section => {
        console.log(section);
        this.state.sectionL1ToShow.push(this.props.labelL2Map?.[section]);
      });
    }

    for (const [key, value] of Object.entries(this.props.labelL1Map)) {
      if (
        value.label === this.props.searchQuery ||
        value.label?.toLowerCase() === this.props.searchQuery ||
        value.label?.toLowerCase() === this.props.searchQuery.toLowerCase()
      ) {
        arrayToShowL1Search.push(true);
      } else {
        arrayToShowL1Search.push(false);
      }
    }
    if (arrayToShowL1Search.includes(true)) {
      this.state.showL1Search = true;
    } else {
      this.state.showL1Search = false;
    }
    // console.log("SEARCHQUERYSHOW", this.state.showL1Search, this.props.searchQuery);

    //if the pages changes
    if (prevProps.category !== this.props.category) {
      this.resetFilters();
    }

    //teacher
    if (prevProps.teacherUsernameValue !== this.props.teacherUsernameValue) {
      if (
        this.props.teacherUsernameValue &&
        !this.state.appliedFilters?.teacher?.includes(this.props.teacherUsernameValue)
      ) {
        let newAppliedFilters = cloneDeep(this.state.appliedFilters);
        newAppliedFilters.teacher = [
          ...(this.state.appliedFilters?.teacher || []),
          this.props.teacherUsernameValue,
        ];
        this.setState({ appliedFilters: newAppliedFilters });
        let newOtherFilters = cloneDeep(this.state.otherFilters);
        newOtherFilters.teacher[this.props.teacherUsernameValue] = true;
        this.setState({ otherFilters: newOtherFilters });
      }
      this.props.setTeacherUsername(null);
    }

    if (prevProps.filteredCourses !== this.props.filteredCourses) {
      // first time
      this.getDisplayedCourses();
    }

    if (prevProps.userProfile?.timezone !== this.props.userProfile?.timezone) {
      this.getDisplayedCourses();
    }

    if (
      this.props.filteredCourses &&
      this.state.displayedCourses &&
      (prevState.limit !== this.state.limit ||
        prevState.sortBy !== this.state.sortBy ||
        prevState.appliedFilters !== this.state.appliedFilters)
    ) {
      this.getDisplayedCourses();
    }

    //when app gets sort by relevance criteria and Relevance is the selected sort --> update courses sort
    if (
      prevProps.isLoadedSortByRelevance !== this.props.isLoadedSortByRelevance &&
      this.props.isLoadedSortByRelevance &&
      this.state.sortBy === RELEVANCE
    ) {
      this.getDisplayedCourses();
    }

    if (prevState.dateSessionRange !== this.state.dateSessionRange) {
      this.getDisplayedCourses();
    }

    if (prevProps.labelComplexityMap !== this.props.labelComplexityMap) {
      this.getDisplayedCourses();
    }

    if (prevState.showOther !== this.state.showOther) {
      useHandleBodyScrollAfterModalOpenMethod(this.state.showOther, !this.props.showCourseModal);
    }

    if (prevProps.areLPCourses !== this.props.areLPCourses && this.props.areLPCourses) {
      this.setState({
        sortBy: LEARNING_SEQUENCE,
      });
    }
  };

  componentWillUnmount = () => {
    // window.removeEventListener('click', this.onClickOutsideHandler);
    this.resetFilters();
  };

  getDisplayedCourses = () => {
    let displayedCourses = this.props.filteredCourses;
    let sections = [],
      complexities = [],
      status = [],
      language = [],
      arentOtherFilters = false,
      brands = [],
      duration = [],
      teacher = [],
      location = [],
      schedule = [],
      others = [],
      price = [],
      offerPlan = [],
      expiration = [],
      dateFixedRange = [];
    let el360 = [];
    let minMaxSessionDateRange = {};
    let courseToFilterMap = cloneDeep(this.state.courseToFilterMap);
    let filterToCourseMap = cloneDeep(this.state.filterToCourseMap);

    const coursesIdFromLPs = getAllLpCoursesId(displayedCourses);
    //the filters are all populated from the parent courses
    //populate filters
    displayedCourses?.map(course => {
      courseToFilterMap[course.courseIdMaster] = new FilterEnableMap();

      //add LP filters only in PLP LP
      if (this.props.removeLessonInLpFilters && coursesIdFromLPs.includes(course.courseIdMaster)) {
        return;
      }

      //if not in subcatalogue brand page
      if (!isBrandSubcataloguePage(window.location.pathname)) {
        //BRAND FILTERS
        let courseBrands = getL1BrandByCourse(course);
        courseBrands?.forEach(brand => {
          updateFilterMaps(
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster,
            'brand',
            brand
          );

          if (!brands.includes(brand)) {
            brands.push(brand);
          }
        });
      }

      //COMPLEXITY FILTERS
      if (course.complexityId && course.macroCategory) {
        let complexityFilter = combinateComplexityCategory(
          course.macroCategory,
          course.complexityId
        );
        updateFilterMaps(
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster,
          'complexity',
          complexityFilter
        );

        if (!complexities.includes(complexityFilter)) {
          complexities.push(complexityFilter);
        }
      }

      //SECTION FILTERS
      let courseSections = course.level2 ;
      courseSections?.forEach(section => {
        updateFilterMaps(
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster,
          'section',
          section
        );

        if (!sections.includes(section)) {
          sections.push(section);
        }
      });

      //DURATION FILTERS
      if (course.duration) {
        let rangeDuration = null;
        if (respectDuration(course, DURATION.LESS5.id)) {
          rangeDuration = DURATION.LESS5.id;
        } else if (respectDuration(course, DURATION.BETWEEN5_10.id)) {
          rangeDuration = DURATION.BETWEEN5_10.id;
        } else if (respectDuration(course, DURATION.BETWEEN10_20.id)) {
          rangeDuration = DURATION.BETWEEN10_20.id;
        } else if (respectDuration(course, DURATION.MORE20.id)) {
          rangeDuration = DURATION.MORE20.id;
        }

        if (rangeDuration) {
          updateFilterMaps(
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster,
            'duration',
            rangeDuration
          );
          if (!duration.includes(rangeDuration)) duration.push(rangeDuration);
        }
      }

      //TEACHER & LOCATION & SCHEDULE FILTERS
      if (course.courseSessions) {
        course.courseSessions.forEach(session => {
          //TEACHER
          if (session.trainer) {
            let trainer = new Trainer();
            trainer.username = session.trainer;
            trainer.firstName = session.trainer;

            updateFilterMaps(
              courseToFilterMap,
              filterToCourseMap,
              course.courseIdMaster,
              'teacher',
              trainer.username
            );

            const isTeacherPresent = teacher.findIndex(a => a.username === trainer.username) >= 0;
            if (!isTeacherPresent) {
              teacher.push(trainer);
            }
          }
          if (session.trainers?.length > 0) {
            session.trainers.forEach(trainer => {
              updateFilterMaps(
                courseToFilterMap,
                filterToCourseMap,
                course.courseIdMaster,
                'teacher',
                trainer.username
              );

              const isTeacherPresent = teacher.findIndex(a => a.username === trainer.username) >= 0;
              if (!isTeacherPresent) {
                teacher.push(trainer);
              }
            });
          }

          //LOCATION
          if (session.city) {
            updateFilterMaps(
              courseToFilterMap,
              filterToCourseMap,
              course.courseIdMaster,
              'location',
              session.city
            );

            if (!location.includes(session.city)) {
              location.push(session.city);
            }
          } else if (session.location) {
            updateFilterMaps(
              courseToFilterMap,
              filterToCourseMap,
              course.courseIdMaster,
              'location',
              session.location
            );

            if (!location.includes(session.location)) {
              location.push(session.location);
            }
          }

          //SCHEDULE
          //not consider past sessions
          if (session.minStartDate && session.status !== sessionStatus.SESSION_OVER) {
            const schedules = getRangeScheduleByDateString(
              session.minStartDate,
              this.props.userProfile?.timezone
            );

            schedules.forEach(sched => {
              updateFilterMaps(
                courseToFilterMap,
                filterToCourseMap,
                course.courseIdMaster,
                'schedule',
                sched
              );

              if (!schedule.includes(sched)) {
                schedule.push(sched);
              }
            });
          }
        });
      }

      //CERTIFICATE LINKS
      if(course?.certificateLinks?.length>0){
        updateFilterMaps(
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster,
          'others',
          FILTER_OTHERS.CERTIFICATE_AVAILABLE
        );
        if (!others.includes(FILTER_OTHERS.CERTIFICATE_AVAILABLE)) {
          others.push(FILTER_OTHERS.CERTIFICATE_AVAILABLE);
        }
      }

      // LIVESTREAM

      if (isLivestreamCourse(course)) {
        //STATUS LIVE NOW
        if (isLivestreamLiveNow(course, this.props.userProfile)) {
          this.addFilterByStatus(
            status,
            'liveNow',
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster
          );
        }

        const liveInfo = course?.liveInfo?.[0];

        // TRAINERS - SPEAKER
        if (liveInfo?.trainers?.length > 0) {
          liveInfo?.trainers?.forEach(tr => {
            const trainer = getUserFromTrainer(tr);
            updateFilterMaps(
              courseToFilterMap,
              filterToCourseMap,
              course.courseIdMaster,
              'teacher',
              trainer.username
            );
            const isTeacherPresent = teacher.findIndex(a => a.username === trainer.username) >= 0;
            if (!isTeacherPresent) {
              teacher.push(trainer);
            }
          });
        }

        //SCHEDULE
        if (liveInfo?.timeStart) {
          const schedules = getRangeScheduleByDateString(
            liveInfo.timeStart,
            this.props.userProfile?.timezone
          );

          schedules.forEach(sched => {
            updateFilterMaps(
              courseToFilterMap,
              filterToCourseMap,
              course.courseIdMaster,
              'schedule',
              sched
            );

            if (!schedule.includes(sched)) {
              schedule.push(sched);
            }
          });
        }

        // OTHERS LIVE RECORDING AVAILABLE
        if (getLivestreamRecording(course, this.props.userProfile)?.length > 0) {
          updateFilterMaps(
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster,
            'others',
            FILTER_OTHERS.RECORDING_AVAILABLE
          );
          if (!others.includes(FILTER_OTHERS.RECORDING_AVAILABLE)) {
            others.push(FILTER_OTHERS.RECORDING_AVAILABLE);
          }
        }
      }


      //STATUS MANDATORY
      if (isMandatory(course) && document.location.pathname !== USER_URLS.RECOMMENDED_IN_TO_DO.URL) {
        this.addFilterByStatus(
          status,
          'mandatory',
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster
        );
      }

      //STATUS RECOMMENDED
      if(isCourseRecommendedInToDo(course) && 
        document.location.pathname !== USER_URLS.RECOMMENDED_IN_TO_DO.URL &&
        document.location.pathname !== USER_URLS.PROFILE_TO_DO.URL
        ) {
        this.addFilterByStatus(
          status,
          'recommended',
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster
        )
      }

      //EL_360
      if (isCourseEl360(course)) {
        updateFilterMaps(
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster,
          'el360',
          FILTER_COURSE.EL_360
        );
        if (!el360.includes(FILTER_COURSE.EL_360)) {
          el360.push(FILTER_COURSE.EL_360);
        }
      }
    

      //STATUS WISHLIST (show only if not in wishlist page)
      if (document.location.pathname !== USER_URLS.WISHLIST.URL && !!course?.wishlist) {
        this.addFilterByStatus(
          status,
          'wishlist',
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster
        );
      }
      //STATUS NEW
      if (isNew(course)) {
        this.addFilterByStatus(
          status,
          'new',
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster
        );
      }
      if (isCourseExpiring(course)) {
        this.addFilterByStatus(
          status,
          'expiring',
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster
        );
      }

      if (isVirtualClassroom(course)) {
        //STATUS BOOKED
        if (isBookedCourse(course)) {
          this.addFilterByStatus(
            status,
            'booked',
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster
          );
        }

        // OTHERS VC RECORDING AVAILABLE
        const {directurl} = getVCRecordingLinks(course)
        if (!!directurl) {
          updateFilterMaps(
            courseToFilterMap,
            filterToCourseMap,
            course.courseId,
            'others',
            FILTER_OTHERS.RECORDING_AVAILABLE
          );
          if (!others.includes(FILTER_OTHERS.RECORDING_AVAILABLE)) {
            others.push(FILTER_OTHERS.RECORDING_AVAILABLE);
          }
        }
      } else if (isLearningPath(course)) {
        // if (course.userCourseStatus === courseStatus.COMPLETED) {
        //     this.addFilterByStatus(status, 'completed', courseToFilterMap, filterToCourseMap, course.courseIdMaster);
        // } else
        if (course.userCourseStatus === courseStatus.ENROLLED) {
          this.addFilterByStatus(
            status,
            'toBeContinued',
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster
          );
        } else {
          this.addFilterByStatus(
            status,
            'toBeStarted',
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster
          );
        }
      }

      //STATUS FILTER
      course.courseStates?.map(state => {
        if (state !== 'toDo' && state !== 'toBegin') {
          this.addFilterByStatus(
            status,
            state,
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster
          );
        }
      });

      if (isCompletedCourse(course)) {
        this.addFilterByStatus(
          status,
          'completed',
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster
        );
      } else if (isStartedCourse(course)) {
        this.addFilterByStatus(
          status,
          'toBeContinued',
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster
        );
      } else if (isCourseToBeStarted(course)) {
        this.addFilterByStatus(
          status,
          'toBeStarted',
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster
        );
      }

      //sort status filters
      status = sortStatusFilters(status);

      //OTHER FILTERS- LANGUAGE
      if (course.totalLanguages) {
        course.totalLanguages.forEach(lang => {
          updateFilterMaps(
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster,
            'language',
            lang.value
          );

          if (!language.includes(lang.value)) {
            language.push(lang.value);
          }
        });
      }
      //add also subtitles to language filters
      const subtitles = getSubtitlesCodes(course);
      subtitles.forEach(lang => {
        updateFilterMaps(
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster,
          'language',
          lang
        );

        if (!language.includes(lang)) {
          language.push(lang);
        }
      });

      //check if the user is enabled to ecommerce
      if (this.props.ecommerceEnable) {
        // FILTERS ECOMMERCE - PRICE
        if (getPriceCourseFilter(course)) {
          const coursePrice = getPriceCourseFilter(course);
          updateFilterMaps(
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster,
            'price',
            coursePrice
          );

          if (!price.includes(coursePrice)) {
            price.push(coursePrice);
          }
        }

        // FILTERS ECOMMERCE - OFFER PLAN
        if (getOfferPlanFilter(course)) {
          const courseOfferPlan = getOfferPlanFilter(course);

          updateFilterMaps(
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster,
            'offerPlan',
            courseOfferPlan
          );

          if (!offerPlan.includes(courseOfferPlan)) {
            offerPlan.push(courseOfferPlan);
          }
        }
      }

      // FILTERS ECOMMERCE - EXPIRATION DATE
      if (getExpirationDateFilterKey(course)) {
        const courseExpirationKey = getExpirationDateFilterKey(course);
        updateFilterMaps(
          courseToFilterMap,
          filterToCourseMap,
          course.courseIdMaster,
          'expiration',
          courseExpirationKey
        );

        if (!expiration.includes(courseExpirationKey)) {
          expiration.push(courseExpirationKey);
        }
      }

      //FIND DATE CALENDAR LIMITS
      if (this.props.showDateSessionFilter) {
        //GET FIXED RANGES
        const fixedRanges = getFixedRange(
          course,
          DATES_FIXED_RANGE,
          this.props.considerDateSessionInTheFuture
        );
        for (const fixedRange of fixedRanges) {
          const idFixedRange =
            fixedRange.toString() +
            '_' +
            (this.props.considerDateSessionInTheFuture ? 'future' : 'past');

          updateFilterMaps(
            courseToFilterMap,
            filterToCourseMap,
            course.courseIdMaster,
            'dateFixedRange',
            idFixedRange
          );

          if (!dateFixedRange.includes(idFixedRange)) {
            dateFixedRange.push(idFixedRange);
          }
        }

        if (course.associatedSession) {
          if (
            !minMaxSessionDateRange.min ||
            minMaxSessionDateRange.min.localeCompare(course.associatedSession.timeStart) > 0
          ) {
            minMaxSessionDateRange.min = course.associatedSession.timeStart;
          }
          if (
            !minMaxSessionDateRange.max ||
            minMaxSessionDateRange.max.localeCompare(course.associatedSession.timeFinish) < 0
          ) {
            minMaxSessionDateRange.max = course.associatedSession.timeFinish;
          }
        } else {
          if (isVirtualClassroom(course)) {
            for (const session of course.courseSessions) {
              if (
                !minMaxSessionDateRange.min ||
                minMaxSessionDateRange.min.localeCompare(session.sessionDates?.[0]?.timeStart) > 0
              ) {
                minMaxSessionDateRange.min = session.sessionDates[0].timeStart;
              }
              if (
                !minMaxSessionDateRange.max ||
                minMaxSessionDateRange.max.localeCompare(session.sessionDates?.[0]?.timeFinish) < 0
              ) {
                minMaxSessionDateRange.max = session.sessionDates[0].timeFinish;
              }
            }
          }

          if (isLivestreamCourse(course)) {
            if (
              !minMaxSessionDateRange.min ||
              minMaxSessionDateRange.min.localeCompare(course.liveInfo?.[0]?.timeStart) > 0
            ) {
              minMaxSessionDateRange.min = course.liveInfo[0]?.timeStart;
            }
            if (
              !minMaxSessionDateRange.max ||
              minMaxSessionDateRange.max.localeCompare(course.liveInfo?.[0]?.timeEnd) < 0
            ) {
              minMaxSessionDateRange.max = course.liveInfo[0]?.timeEnd;
            }
          }
        }
      }
    });

    if (this.props.showDateSessionFilter) {
      const today = new Date();
      if (this.props.considerDateSessionInTheFuture) {
        //show min 3 months in the future
        minMaxSessionDateRange.min = today.toString();
        if (
          (new Date(minMaxSessionDateRange.max).getTime() - today.getTime()) /
            1000 /
            60 /
            60 /
            24 /
            30 <
          3
        ) {
          minMaxSessionDateRange.max = getDaysInTheFuture(90).toString();
        }
      } else {
        //show min 3 months in the past
        minMaxSessionDateRange.max = today.toString();
        if (
          (today.getTime() - new Date(minMaxSessionDateRange.min).getTime()) /
            1000 /
            60 /
            60 /
            24 /
            30 <
          3
        ) {
          minMaxSessionDateRange.min = getDaysAgo(90).toString();
        }
      }
    }

    let resultsObj = this.updateFilteredCourses(
      this.props.filteredCourses,
      this.state.appliedFilters,
      courseToFilterMap,
      filterToCourseMap
    );
    if (
      sections.length === 0 &&
      complexities.length === 0 &&
      status.length === 0 &&
      language.length === 0 &&
      brands.length === 0 &&
      duration.length === 0 &&
      teacher.length === 0 &&
      location.length === 0 &&
      schedule.length === 0 &&
      others.length === 0 &&
      price.length === 0 &&
      offerPlan.length === 0 &&
      expiration.length === 0 &&
      dateFixedRange.length === 0 &&
      el360.length === 0
    ) {
      arentOtherFilters = true;
    }

    //SORT FILTERS
    language = orderBy(language);
    duration = sortDurationFilters(duration);
    teacher = sortTrainers(teacher);
    location = orderBy(location);
    schedule = sortScheduleFilters(schedule);
    price = orderBy(price);
    offerPlan = orderBy(offerPlan);
    expiration = sortExpirations(expiration);
    this.setState({
      displayedCourses: resultsObj.displayedCourses,
      filterResultsCourses: resultsObj.filterResultsCourses,
      filterResultsLP: resultsObj.filterResultsLP,
      allOtherFilters: {
        sections,
        language,
        complexities,
        status,
        duration,
        teacher,
        location,
        schedule,
        others,
        price,
        offerPlan,
        expiration,
        dateFixedRange,
        brands,
        el360
      },
      arentOtherFilters,
      courseToFilterMap,
      filterToCourseMap,
      minMaxSessionDateRange,
    });
  };

  resetFilters = () => {
    this.setState({
      otherFilters: { ...initialOtherFilters },
      oldFilters: {},
      appliedFilters: {}, //map by filterName: filterGroup
      filterOtherActive: false,
      showOther: false,
      dateSessionRange: null,
    });
  };

  setShowOther = () =>
    this.setState(prevState => {
      return { showOther: !prevState.showOther };
    });

  toggleAppliedFilters = (key, value) => {
    let appliedFilters = { ...this.state.appliedFilters };

    if (appliedFilters[key]) {
      if (appliedFilters[key].includes(value)) {
        if (appliedFilters[key].length === 1) {
          delete appliedFilters[key];
        } else {
          for (var i = 0; i < appliedFilters[key].length; i++) {
            if (appliedFilters[key][i] === value) {
              appliedFilters[key].splice(i, 1);
            }
          }
        }
      } else {
        appliedFilters[key].push(value);
      }
    } else {
      appliedFilters[key] = [value];
    }

    return appliedFilters;
  };

  toggleOther = (filter, type, mutualExclusion = false, section, flagCheckedShow = false) => {
    let otherFilters = cloneDeep(this.state.otherFilters);
    console.log('QUI', filter, this.state.sectionL1ToShow);

    if (flagCheckedShow) {
      section.flagChecked = !section.flagChecked;
    }
    this.state.sectionL1ToShow.forEach(item => {
      if (item?.key === filter) {
        if (item.flagChecked) {
          item.flagChecked = false;
        }
      }
    });
    if (otherFilters[type]) {
      if (mutualExclusion) {
        if (otherFilters[type][filter]) {
          delete otherFilters[type][filter];
        } else {
          //clean all other fields
          otherFilters[type] = {};
          //set the selected element to true
          otherFilters[type][filter] = true;
        }
      } else {
        if (!!otherFilters[type][filter]) {
          delete otherFilters[type][filter];
        } else {
          otherFilters[type][filter] = true;
        }
      }
    }

    let appliedFilters = { ...this.state.appliedFilters };
    if (mutualExclusion) {
      if (Object.keys(otherFilters[type])?.length > 0) {
        appliedFilters[type] = Object.keys(otherFilters[type]);
      } else {
        delete appliedFilters[type];
      }
    } else {
      appliedFilters = this.toggleAppliedFilters(type, filter);
    }
    let filterOtherActive = checkIfObjHasValues(otherFilters);

    if (
      Object.keys(otherFilters?.price)?.length === 0 &&
      (this.state.sortBy === PRICE_ASC || this.state.sortBy === PRICE_DES)
    ) {
      this.setSortBy(NEW);
    }

    this.setState({
      otherFilters,
      appliedFilters,
      filterOtherActive,
    });
  };

  setDateSessionRange = (type, inFuture = false, min = null, max = null) => {
    // type = 7 | 14 | 30 | 'CUSTOM'
    let dateSessionRange = cloneDeep(this.state.dateSessionRange);
    let actualType = dateSessionRange?.type;

    const areMixMaxValued = areMinMaxValuedInDateSessionRange(min, max);

    if (actualType === type) {
      //remove filter
      if (type !== DATE_CUSTOM_RANGE || !areMixMaxValued) {
        dateSessionRange = {};
        this.setState({
          dateSessionRange,
        });
        return;
      }
    }

    if (type !== DATE_CUSTOM_RANGE) {
      const startDate = inFuture ? getTodayMidnight() : getDaysAgo(type);
      const endDate = inFuture ? getDaysInTheFuture(type) : getTodayMidnight(true);

      dateSessionRange = {
        min: startDate,
        max: endDate,
      };
    } else {
      if (areMixMaxValued) {
        dateSessionRange = {
          min: min,
          max: max,
        };
      } else {
        //no dates already selected
        dateSessionRange = {};
      }
    }

    dateSessionRange.type = type;
    dateSessionRange.inFuture = inFuture;
    // console.log('dateSessionRange', dateSessionRange);
    this.setState({
      dateSessionRange,
    });
  };

  clearAll = () => {
    let appliedFilters = { ...this.state.appliedFilters };
    const otherCat = Object.keys(initialOtherFilters);

    otherCat.forEach(cat => {
      if (appliedFilters[cat]) {
        delete appliedFilters[cat];
      }
    });

    this.setState({
      otherFilters: { ...initialOtherFilters },
      appliedFilters,
      filterOtherActive: false,
      dateSessionRange: {},
    });
  };

  removeAppliedFilter = (filterName, filterGroup) => {
    let appliedFilters = this.toggleAppliedFilters(filterGroup, filterName);
    let filterList = { ...this.state['otherFilters'][filterGroup] };
    delete filterList[filterName];

    let otherFilters = {
      ...this.state.otherFilters,
      [filterGroup]: filterList,
    };

    let filterOtherActive = checkIfObjHasValues(otherFilters);

    this.setState({
      otherFilters,
      appliedFilters,
      filterOtherActive,
    });
  };

  setSortBy = param => this.setState({ sortBy: param });
  setLimit = () =>
    this.setState(prevState => {
      return { limit: prevState.limit + this.props.pagination };
    });

  openModalFilter = () => {
    //store old filters to have the possibility to revert them
    this.setState({
      oldFilters: {
        otherFilters: cloneDeep(this.state.otherFilters),
        appliedFilters: cloneDeep(this.state.appliedFilters),
        filterOtherActive: cloneDeep(this.state.filterOtherActive),
        dateSessionRange: cloneDeep(this.state.dateSessionRange),
      },
      activatedFiltersAnalytics: true,
    });

    this.setShowOther();
  };

  cancelFilter = () => {
    //revert filters before the filter modal opening
    this.setState({
      otherFilters: cloneDeep(this.state.oldFilters.otherFilters),
      appliedFilters: cloneDeep(this.state.oldFilters.appliedFilters),
      filterOtherActive: cloneDeep(this.state.oldFilters.filterOtherActive),
      dateSessionRange: cloneDeep(this.state.dateSessionRange),
    });
  };

  updateFilteredCourses = (courses, appliedFilters, courseToFilterMap, filterToCourseMap) => {
    let coursesToFilter = cloneDeep(courses),
      limit = this.state.limit;
    //0) split LP and courses
    let LPToFilter = coursesToFilter?.filter(a => isLearningPath(a));
    //if sort by relevance --> sort also LPs
    if (
      this.state.sortBy === SORTBY.RELEVANCE ||
      this.state.sortBy === SORTBY.RELEVANCE_FAKE ||
      this.state.sortBy === SORTBY.RELEVANCE_SEARCH
    ) {
      LPToFilter = sortCourses(LPToFilter, SORTBY.RELEVANCE);
    } else {
      LPToFilter = sortCourses(LPToFilter, SORTBY.NEW);
    }

    //1) filter courses

    //COMPLEXITY FILTERS
    if (appliedFilters.complexity) {
      coursesToFilter = filterByComplexities(coursesToFilter, appliedFilters.complexity, true);
    }

    //BRAND FILTERS
    if (appliedFilters.brand) {
      coursesToFilter = filterByChannels(coursesToFilter, appliedFilters.brand);
    }

    //SECTION FILTERS
    if (appliedFilters.section) {
      console.log('AppliedFilter', appliedFilters);
      coursesToFilter = filterBySections(coursesToFilter, appliedFilters.section);
    }

    //--LANGUAGE (AND SUBTITLES)
    if (appliedFilters.language) {
      coursesToFilter = filterByLanguage(coursesToFilter, appliedFilters.language);
    }

    //--GENERAL STATUS
    if (appliedFilters.status) {
      coursesToFilter = coursesToFilter.filter(course => {
        let retVal = false;
        appliedFilters.status.forEach(group => {
          if (this.filterByStatus(course, group)) retVal = true;
        });
        return retVal;
      });
    }

    //--DURATION
    if (appliedFilters.duration) {
      coursesToFilter = filterByDurations(coursesToFilter, appliedFilters.duration);
    }

    //--TEACHER
    if (appliedFilters.teacher) {
      coursesToFilter = filterByTeachers(coursesToFilter, appliedFilters.teacher);
    }

    //--LOCATION
    if (appliedFilters.location) {
      coursesToFilter = filterByLocations(coursesToFilter, appliedFilters.location);
    }

    //--SCHEDULE
    if (appliedFilters.schedule) {
      coursesToFilter = filterBySchedules(
        coursesToFilter,
        appliedFilters.schedule,
        this.props.userProfile?.timezone
      );
    }

    //-- RECORDING AVAILABLE
    if (appliedFilters.others?.includes("recording_available")) {
      coursesToFilter = filterByRecordingAvailable(
        coursesToFilter,
        appliedFilters.others,
        this.props.userProfile
      );
    }

        //-- CERTIFICTE AVAILABLE
        if (appliedFilters.others?.includes("certificate_available")) {
          coursesToFilter = filterByCertificateAvailable(
            coursesToFilter,
            appliedFilters.others,
            this.props.userProfile
          );
        }

    //EL360
    if (appliedFilters.el360) {
      coursesToFilter = filterByEl360Courses(
        coursesToFilter,
        appliedFilters.el360,
      );
    }

    //--PRICE
    if (appliedFilters.price) {
      coursesToFilter = filterByPrice(coursesToFilter, appliedFilters.price);
    }

    //--OFFER PLAN
    if (appliedFilters.offerPlan) {
      coursesToFilter = filterByOfferPlan(coursesToFilter, appliedFilters.offerPlan);
    }

    //--EXPIRATION DATE
    if (appliedFilters.expiration) {
      coursesToFilter = filterByExpiration(coursesToFilter, appliedFilters.expiration);
    }

    //--DATE SESSION RANGE
    if (this.state.dateSessionRange) {
      coursesToFilter = filterByDateSessionRange(coursesToFilter, this.state.dateSessionRange);
    }

    //--DATE FIXED RANGE
    if (appliedFilters.dateFixedRange) {
      coursesToFilter = filterByDateFixedRange(coursesToFilter, appliedFilters.dateFixedRange);
    }

    //2) order courses
    let orderedCourses = sortCourses(
      coursesToFilter,
      this.state.sortBy,
      this.props.sortByRelevanceCriteria,
      this.props.userProfile
    );

    //3) paginate courses
    let displayedCourses = orderedCourses.filter((item, i) => i < limit);

    //4) create filter disable map
    let filterEnableMap = createFilterEnableMap(
      appliedFilters,
      filterToCourseMap,
      courseToFilterMap
    );
    this.setState({ filterEnableMap: filterEnableMap });

    return {
      displayedCourses,
      filterResultsCourses: coursesToFilter,
      filterResultsLP: LPToFilter,
    };
  };

  printLoader = n => {
    let toPrint = [];

    for (let i = 0; i < n; i++) {
      toPrint.push(
        <div className="loader-virtual-container" key={i}>
          <CardClassroomLoader isWhite />
        </div>
      );
    }

    return toPrint;
  };

  mapFilterStatusToCourseState = status => {
    switch (status) {
      case 'toBeStarted':
        return ['upcoming', 'scheduled', 'missed'];
      case 'scheduled':
        return [status];
      case 'toBeContinued':
        return ['inProgress'];
      case 'completed':
        return ['done', 'attended'];
      case 'overdue':
        return [status];
      case 'missed':
        return [status];
      case 'new':
      case 'booked':
      case 'mandatory':
      case 'recommended':
      case 'wishlist':
        return [status];
      default:
        return [status];
    }
  };

  mapCourseStateToFilterStatus = state => {
    switch (state) {
      case 'inProgress':
        return ['toBeContinued'];
      case 'overdue':
        return [state];
      case 'done':
        return ['completed'];
      case 'upcoming':
        return ['toBeStarted'];
      case 'scheduled':
        return [state];
      case 'attended':
        return ['completed'];
      case 'missed':
        return [state];
      case 'toBeStarted':
        return ['toBeStarted'];
      case 'completed':
        return ['completed'];
      case 'toBeContinued':
        return ['toBeContinued'];
      case 'new':
      case 'booked':
      case 'mandatory':
      case 'recommended':
      case 'wishlist':
      case 'expiring':
      case 'liveNow':
        return [state];
      default:
        return [];
    }
  };

  filterByStatus = (course, status) => {
    if (!course) {
      return false;
    }
    if (!status) {
      return false;
    }

    if (status === 'new' && isNew(course)) {
      return true;
    }
    if (status === 'booked' && isBookedCourse(course)) {
      return true;
    }
    if (status === 'mandatory' && isMandatory(course)) {
      return true;
    }
    if (status === 'wishlist' && !!course?.wishlist) {
      return true;
    }
    if (status === 'expiring' && isCourseExpiring(course)) {
      return true;
    }
    if (status === 'recommended' && isCourseRecommendedInToDo(course)) {
      return true;
    }

    if (isCompletedCourse(course)) {
      return status === 'completed';
    } else if (isLivestreamCourse(course) && isLivestreamLiveNow(course, this.props.userProfile)) {
      return status === 'liveNow' ? true : status === 'toBeContinued';
    } else if (isStartedCourse(course)) {
      return status === 'toBeContinued';
    } else if (isMissedCourse(course)) {
      return status === 'missed';
    }

    let courseStates = course.courseStates;
    if (!courseStates) {
      return false;
    }

    // if (courseStates.length === 0 && isVideoOrPodcast(course)) {
    //     if (status === 'toBeStarted' && !isCompletedCourse(course)) {
    //         return true;
    //     }
    // }

    const statusList = this.mapFilterStatusToCourseState(status);

    for (const stat of statusList) {
      if (courseStates.includes(stat)) {
        return true;
      }
    }

    if (
      status === 'toBeStarted' &&
      !course.courseStates.includes('inProgress') &&
      !course.courseStates.includes('done') &&
      !course.courseStates.includes('attended') &&
      !course.courseStates.includes('overdue')
    ) {
      return true;
    }

    // if ((isOnlineCourse(course) || isLearningPath(course) || isOnlyMultiActivity(course)) && course.userCourseStatus === courseStatus.ENROLLED) {
    //     if (status === 'toBeContinued') {
    //         return true;
    //     }
    // } else if (status === 'toBeStarted' && !course.courseStates.includes('inProgress') && !course.courseStates.includes('done')
    //     && !course.courseStates.includes('attended') && !course.courseStates.includes('overdue')) {
    //     return true;
    // }

    return false;
  };

  addFilterByStatus = (status, state, courseToFilterMap, filterToCourseMap, courseIdMaster) => {
    if (!status) {
      return;
    }

    if (!state || state == 'toDo' || state == 'toBegin') {
      return;
    }

    const mapStates = this.mapCourseStateToFilterStatus(state);
    for (const mapState of mapStates) {
      updateFilterMaps(courseToFilterMap, filterToCourseMap, courseIdMaster, 'status', mapState);

      if (!status.includes(mapState)) {
        status.push(mapState);
      }
    }

    return;
  };

  printChipLabelOrderDate = (dateSessionRange, lang) => {
    if (!dateSessionRange) {
      return '';
    }

    if (typeof dateSessionRange.type == 'number') {
      return (dateSessionRange.inFuture ? lang?.NEXT_X_DAYS : lang?.LAST_X_DAYS)?.replace(
        '{NUM}',
        dateSessionRange.type
      );
    } else {
      const startDateLabel = printDate(dateSessionRange.min, lang);
      const endDateLabel = printDate(dateSessionRange.max, lang);
      return lang?.FROM_TO_ORDER_DATE?.replace('{0}', startDateLabel).replace('{1}', endDateLabel);
    }
  };

  showChipDateSessionRange = dateSessionRange => {
    if (!dateSessionRange) {
      return false;
    }

    if (typeof dateSessionRange.type == 'number') {
      return true;
    } else {
      return areMinMaxValuedInDateSessionRange(dateSessionRange.min, dateSessionRange.max);
    }
  };

  printChipLabel = (key, filter, lang) => {
    if (!key || !filter) {
      return '';
    }
    switch (key) {
      case 'complexity':
        const complexity = getComplexityFromComplexityFilter(filter);
        return getLevelLabel(complexity, this.props.labelComplexityMap) || filter;
      case 'teacher':
        return getTrainerFullNameByUsername(filter, this.props.coursesMap);
      case 'location':
        return filter;
      case 'language':
        return this.props.printLanguagesMap ? this.props.printLanguagesMap[filter] : filter;
      case 'section':
        return fromCamelToCapitalize(getLevelLabel(filter, this.props.labelL2Map) || filter);
      case 'brand':
        return getLevelLabel(filter, this.props.labelL1Map) || filter;
      case 'duration':
        return getDurationLabel(filter, lang);
      case 'schedule':
        return getScheduleLabel(filter, lang);
      case 'others':
        if(filter === FILTER_OTHERS.RECORDING_AVAILABLE)
          return lang?.LIVESTREAM_RECORDING_AVAILABLE || 'LIVESTREAM_RECORDING_AVAILABLE';
        else if((filter === FILTER_OTHERS.CERTIFICATE_AVAILABLE))
          return lang?.CERTIFICATE_AVAILABLE|| 'CERTIFICATE_AVAILABLE';
          return filter;
      case 'price':
        return getPriceLabel(filter, lang , this.props.userProfile?.ecommerceInfo?.currency, this.props.userProfile?.preferredLang);
      case 'offerPlan':
        return getOfferPlanLabel(filter, lang);
      case 'expiration':
        return getExpirationLabel(filter, lang);
      case 'dateFixedRange':
        return getDateFixedRangeLabel(filter, lang);
        case 'el360':
          return filter === FILTER_COURSE.EL_360 ? lang?.EL360_LABEL : ''
      default:
        return this.props.filterLabels ? this.props.filterLabels[filter] : filter;
    }
  };
  render() {
    let {
      pagination,
      lang,
      isLoadingCourses,
      isGetCourseCompletionCompleted,
      printLanguagesMap,
      filterLabels,
      isLoadingSearchCourses,
      isLoadingGetUserRelevantSession
    } = this.props;
    const {
      categoryName,
      subCategoryName,
      selectedChannel,
      timeOnTraining,
      isTimeOnTrainingLoaded,
      searchQuery,
      searchType,
      showCourseModal,
      labelL1Map,
      labelL2Map,
      labelComplexityMap,
      userProfile,
      searchL1,
    } = this.props; // analytics
    let {
      filterOtherActive,
      showOther,
      appliedFilters,
      sortBy,
      displayedCourses,
      allOtherFilters,
      filterResultsCourses,
      filterResultsLP,
      arentOtherFilters,
      sectionL1ToShow,
      showL1Search,
    } = this.state;

    let isLoading =
      (this.props.category === 'mycertificates'
        ? !isGetCourseCompletionCompleted
        : this.props.category === 'search'
        ? isLoadingCourses || isLoadingSearchCourses
        : isLoadingCourses) || this.props.isLoading;


    const COMPLETION_SORT = isProfileProgressOrCertificates(window.location.pathname)
      ? {
          [COMPLETION]: {
            value: COMPLETION,
            labelMobile: lang.COMPLETION,
            label: lang.COMPLETION
              ? lang.SORT_BY_LABEL.replace('{sorting}', lang.COMPLETION)
              : lang.COMPLETION,
          },
        }
      : {};

    let sortings = {
      [RATING]: {
        value: RATING,
        labelMobile: lang.MOST_LIKE_LABEL,
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.MOST_LIKE_LABEL)
          : lang.MOST_LIKE_LABEL,
      },
      [NEW]: {
        value: NEW,
        labelMobile: lang.NEWEST_LABEL,
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.NEWEST_LABEL)
          : lang.NEWEST_LABEL,
      },
      [RELEVANCE]: {
        value: RELEVANCE,
        labelMobile: lang.RELEVANCE,
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.RELEVANCE_LABEL)
          : lang.RELEVANCE_LABEL,
      },
      // [RECOMMANDATION]: { value: RECOMMANDATION, labelMobile: lang.COMPATIBILITY_LABEL, label: lang.SORT_BY_LABEL ? lang.SORT_BY_LABEL.replace('{sorting}', lang.COMPATIBILITY_LABEL) : lang.COMPATIBILITY_LABEL },
      [SHORTEST]: {
        value: SHORTEST,
        labelMobile: lang.SHORTEST_LABEL,
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.SHORTEST_LABEL)
          : lang.SHORTEST_LABEL,
      },
      [TITLE_AZ]: {
        value: TITLE_AZ,
        labelMobile: lang.TITLE_AZ_LABEL,
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.TITLE_AZ_LABEL)
          : lang.TITLE_AZ_LABEL,
      },
      [TITLE_ZA]: {
        value: TITLE_ZA,
        labelMobile: lang.TITLE_ZA_LABEL,
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.TITLE_ZA_LABEL)
          : lang.TITLE_ZA_LABEL,
      },
      [UPCOMING]: {
        value: UPCOMING,
        labelMobile: lang.UPCOMING_LABEL,
        label: lang.UPCOMING_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.UPCOMING_LABEL)
          : lang.UPCOMING_LABEL,
      },
      ...COMPLETION_SORT,
    };

    if (isSearchPage(window.location.pathname)) {
      sortings[RELEVANCE] = {
        value: RELEVANCE_FAKE,
        labelMobile: lang.RELEVANCE_FAKE,
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.RELEVANCE_LABEL)
          : lang.RELEVANCE_LABEL,
      };
    }

    if (isProfileProgressOrCertificates(window.location.pathname)) {
      sortings = {
        [TITLE_AZ]: sortings[TITLE_AZ],
        [TITLE_ZA]: sortings[TITLE_ZA],
        ...COMPLETION_SORT,
      };
    }

    const isAnyCoursePurchasable = filterResultsCourses.some(course => isPurchasable(course));

    if (isAnyCoursePurchasable) {
      sortings[PRICE_DES] = {
        value: PRICE_DES,
        labelMobile: lang.PRICE_DES_LABEL,
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.PRICE_DES_LABEL)
          : lang.PRICE_DES_LABEL,
      };
      sortings[PRICE_ASC] = {
        value: PRICE_ASC,
        labelMobile: lang.PRICE_ASC_LABEL,
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.PRICE_ASC_LABEL)
          : lang.PRICE_ASC_LABEL,
      };
    }

    if (this.props.defaultSortBy === RELEVANCE) {
      sortings[RELEVANCE] = {
        value: RELEVANCE,
        labelMobile: lang.RELEVANCE_LABEL || '',
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.RELEVANCE_LABEL)
          : lang.RELEVANCE_LABEL,
      };
    }
    if (this.props.defaultSortBy === START_DATE_LIVESTREAM) {
      sortings[START_DATE_LIVESTREAM] = {
        value: START_DATE_LIVESTREAM,
        labelMobile: lang.SORT_BY_LIVESTREAM_DATE || '',
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.SORT_BY_LIVESTREAM_DATE)
          : lang.SORT_BY_LIVESTREAM_DATE,
      };
    }
    if (this.props.defaultSortBy === START_DATE_LIVESTREAM_ASC) {
      sortings[START_DATE_LIVESTREAM_ASC] = {
        value: START_DATE_LIVESTREAM_ASC,
        labelMobile: lang.SORT_BY_LIVESTREAM_DATE || '',
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.SORT_BY_LIVESTREAM_DATE)
          : lang.SORT_BY_LIVESTREAM_DATE,
      };
    }

    if (this.props.defaultSortBy === RELEVANCE_FAKE) {
      sortings[RELEVANCE_FAKE] = {
        value: RELEVANCE_FAKE,
        labelMobile: lang.RELEVANCE_LABEL || '',
        label: lang.SORT_BY_LABEL
          ? lang.SORT_BY_LABEL.replace('{sorting}', lang.RELEVANCE_LABEL)
          : lang.RELEVANCE_LABEL,
      };
    }

    if (this.props.areLPCourses) {
      sortings[LEARNING_SEQUENCE] = {
        value: LEARNING_SEQUENCE,
        label: lang.SORT_BY_LEARNING_SEQUENCE,
      };
    }

    let appliedFiltersNum = 0;
    if (!!Object.keys(appliedFilters)) {
      for (const appliedFilterKey in appliedFilters) {
        appliedFiltersNum += appliedFilters[appliedFilterKey].length;
      }
    }
    //if custom date range is active and selected
    if (isDateSessionRangeFilterApplied(this.state.dateSessionRange)) {
      appliedFiltersNum++;
    }

    //let filterAlreadyPresent = false
    let arrayFilterModal = [];
    let arrayFilterSearch = [];
    sectionL1ToShow.forEach(key => {
      arrayFilterSearch.push(key?.key);
    });
    // console.log("APPLIED_FILTER", appliedFilters);
    Object.keys(appliedFilters).map(key => {
      appliedFilters[key].map(filter => {
        /*  if(key === "section" && arrayFilterSearch.includes(filter)){
             delete appliedFilters[key];
         } */
        arrayFilterModal.push(filter);
      });
    });

    if (arrayFilterSearch.includes(arrayFilterModal)) {
      filterAlreadyPresent = true;
    }
    let filterAlreadyPresent = arrayFilterModal.every(element => {
      if (arrayFilterSearch.includes(element)) {
        sectionL1ToShow.forEach(key => {
          if (key?.key === element) {
            key.flagChecked = true;
            console.log('array filter search inside true', key, element);
          }
        });
        return true;
      } else {
        return false;
      }
      //return arrayFilterSearch.includes(element);
    });

    // console.log("ARRAY FILTER", sectionL1ToShow, appliedFilters);
    const CoursesResultsWrapper =
      typeof this.props.rightAnimation === 'boolean' ? AnimationSlide : React.Fragment;

    return (
      <div>
        {((this.props.filteredCourses && this.props.filteredCourses.length > 0) ||
          this.props.forceRendering) && (
          <div className={'filtersV3__section ' + (showOther ? 'filtersV3__section--open' : '') + (this.props.className ? ` ${this.props.className} ` : '')}>
            <div className="wrapper">
              <div className={clsx("filter-overlay flex", {[this.props.className]: this.props.className})}>
                {this.props.filterOverlayComponent}

                <div
                  className="filter-overlay-element results"
                  role="region"
                  aria-label={
                    isLoading
                      ? lang.LOADING
                      : lang.RESULTS_LABEL.replace('{0}', displayedCourses.length).replace(
                          '{1}',
                          filterResultsCourses.length
                        )
                  }
                >
                  {!this.props.hideResultsNumber && !isLoading && (
                    <span className="results__number-courses">
                      {lang.RESULTS_LABEL.replace('{0}', displayedCourses.length).replace(
                        '{1}',
                        filterResultsCourses.length
                      )}
                    </span>
                  )}
                </div>
                <div className="results__sort-wrapper filter-overlay-element">
                  {sortBy && !this.props.hideSortingOutsideModal && (
                    <section aria-label={lang?.TITLE_SORT_BY_LABEL}>
                      <Select
                        className={"custom-select-sortings " + (this.props.className ? this.props.className : '')}
                        options={Object.values(sortings)}
                        noMaxHeightList
                        onChange={option => this.setSortBy(option.value)}
                        value={sortings[sortBy]}
                        aria-label={sortings?.[sortBy]?.label}
                        keyboardActive
                        noFillMobile
                      />
                    </section>
                  )}
                </div>

                <div
                  className={`filtersV3__box${
                    filterOtherActive ? ' filtersV3__box--active' : ''
                  } filter-others filter-overlay-element right`}
                >
                  {!arentOtherFilters && (
                    <>
                      {this.props.customIcons}
                      <section className="flex" aria-label={lang.FILTER_APPLIED_LABEL}>
                        {appliedFiltersNum > 0 && (
                          <span className="text-12 filtersV3__filters-num">
                            {appliedFiltersNum}
                          </span>
                        )}
                        <ButtonV2
                          variant="icon-btn"
                          className="icon-button button-filter button-filter-toggle"
                          handleClick={this.openModalFilter}
                          ariaLabel={lang?.OPEN_FILTERS_MODAL}
                        >
                          {/* {this.state.bSearchPage ? ( */}
                          <SettingsIcon
                            aria-hidden="true"
                            className={
                              'filters-icon' + (appliedFiltersNum > 0 ? ' theme-evolution' : '')
                            }
                          />
                          {/* ) : (
                                  <FiltersIcon
                                    className={'filters-icon' + (appliedFiltersNum > 0 ? ' theme-evolution' : '')}
                                    aria-hidden="true"
                                  />
                              )} */}
                        </ButtonV2>
                      </section>
                      <FilterModal
                        close={this.setShowOther}
                        show={showOther}
                        filters={allOtherFilters}
                        // analytics
                        subCategoryName={subCategoryName}
                        categoryName={categoryName}
                        selectedChannel={selectedChannel}
                        timeOnTraining={timeOnTraining}
                        isTimeOnTrainingLoaded={isTimeOnTrainingLoaded}
                        isLoading={isLoading}
                        searchQuery={searchQuery}
                        searchType={searchType}
                        showCourseModal={showCourseModal}
                        category={this.props.category}
                        activatedFiltersAnalytics={this.state.activatedFiltersAnalytics}
                        // analytics
                        selectedFilters={this.state.otherFilters}
                        isFromCarousel={this.props.isFromCarousel}
                        toggleOther={this.toggleOther}
                        lang={lang}
                        label={filterLabels}
                        printLanguagesMap={printLanguagesMap}
                        clearAllFilters={this.clearAll}
                        filterEnableMap={this.state.filterEnableMap}
                        labelL1Map={labelL1Map}
                        labelL2Map={labelL2Map}
                        labelComplexityMap={labelComplexityMap}
                        numCourses={filterResultsCourses ? filterResultsCourses.length : 0}
                        filteredCourses={filterResultsCourses}
                        sortings={sortings}
                        sortBy={sortBy}
                        setSortBy={this.setSortBy}
                        cancelFilter={this.cancelFilter}
                        userProfile={this.props.userProfile}
                        showSortingInModalDesktop={this.props.showSortingInModalDesktop}
                        hideSortingInModal={this.props.hideSortingInModal}
                        setDateSessionRange={this.setDateSessionRange}
                        dateSessionRange={this.state.dateSessionRange}
                        minMaxSessionDateRange={this.state.minMaxSessionDateRange}
                        considerDateSessionInTheFuture={this.props.considerDateSessionInTheFuture}
                        showDateSessionFilter={this.props.showDateSessionFilter}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {(!!Object.keys(appliedFilters).length ||
          this.showChipDateSessionRange(this.state.dateSessionRange) ||
          (sectionL1ToShow?.length > 0 && showL1Search)) &&
          !isLoading && (
            <section className="filtersV3__section chips" aria-label={lang.SEARCH_IN}>
              {sectionL1ToShow?.length > 0 && showL1Search && (
                <span className="titleSearch">{lang.SEARCH_IN} </span>
              )}
              <div className="wrapper">
                <div className="results overflow">
                  <div className="results__header flex">
                    <section className="results__filters" aria-label={lang.FILTER_APPLIED_LABEL}>
                      <div className="filter-modal__topics advancedSearch">
                        {sectionL1ToShow.map((item, index) => (
                          <>
                            {sectionL1ToShow?.length > 0 && showL1Search && (
                              <Chip
                                key={item?.key + '_' + index}
                                label={item?.label}
                                showClose
                                checked={item?.flagChecked} //{selectedFilters.section[item.sectionId]} {item.flagChecked}
                                toggle={() => {
                                  this.toggleOther(item.key, 'section', false, item, true);
                                }}
                                className="button-filter results__filter"
                              />
                            )}
                          </>
                        ))}

                        {Object.keys(appliedFilters).map(key =>
                          appliedFilters[key].map(filter => (
                            <>
                              {/* {!filterAlreadyPresent && */}
                              {!filterAlreadyPresent && (
                                <Chip
                                  key={filter}
                                  checked={true}
                                  showClose={true}
                                  className="button-filter results__filter"
                                  toggle={() => this.removeAppliedFilter(filter, key)}
                                  label={this.printChipLabel(key, filter, lang)}
                                />
                              )}
                            </>
                          ))
                        )}
                        {this.showChipDateSessionRange(this.state.dateSessionRange) && (
                          <Chip
                            checked={true}
                            showClose={true}
                            className="button-filter results__filter"
                            toggle={() =>
                              this.setDateSessionRange(this.state.dateSessionRange.type)
                            }
                            label={this.printChipLabelOrderDate(this.state.dateSessionRange, lang)}
                          />
                        )}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          )}
        <div
          className={
            'section filtersV3__courses-results-container ' +
            (filterResultsCourses?.length > 0 ? '' : 'empty') +
            (this.props.className && ` ${this.props.className} `)
          }
        >
          {this.props.filteredCourses?.length > 0 && (
            <div
              className="filter-overlay-element results-mobile"
              role="region"
              aria-label={
                isLoading
                  ? lang.LOADING
                  : lang.RESULTS_LABEL.replace('{0}', displayedCourses.length).replace(
                      '{1}',
                      filterResultsCourses.length
                    )
              }
            >
              {!this.props.hideResultsNumber && !isLoading && (
                <span className="results__number-courses">
                  {lang.RESULTS_LABEL.replace('{0}', displayedCourses.length).replace(
                    '{1}',
                    filterResultsCourses.length
                  )}
                </span>
              )}
            </div>
          )}
          <CoursesResultsWrapper
            {...(this.props.rightAnimation
              ? animationSlideToRightDefault
              : animationSlideToLeftDefault)}
            initialX={animationXSection}
            duration={animationDurationSection}
          >
            <CoursesResults
              setLimit={this.setLimit}
              pagination={pagination}
              lang={lang}
              filteredCourses={filterResultsCourses}
              displayedCourses={displayedCourses}
              isLoading={isLoading}
              upcomingEventsTab={this.props.upcomingEventsTab}
              hideResultsNumber={this.props.hideResultsNumber}
              hideSortingInModal={this.props.hideSortingInModal}
              noResults={
                !!Object.keys(appliedFilters).length ||
                this.showChipDateSessionRange(this.state.dateSessionRange)
              }
              cardVariant={this.props.cardVariant}
              isSearch={this.state.bSearchPage}
              sortBy={sortBy}
              className={this.props.className}
            />
          </CoursesResultsWrapper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pagination: state.course.pagination,
    isBySearchCompleted: state.course.isBySearchCompleted,
    lang: state.utils.lang,
    searchResults: state.course.searchResults,
    labelL1Map: state.utils.labelL1Map,
    labelL2Map: state.utils.labelL2Map,
    labelComplexityMap: state.utils.labelComplexityMap,
    isLoadingCourses: state.course.isLoadingCourses,
    isGetCourseCompletionCompleted: state.course.isGetCourseCompletionCompleted,
    printLanguagesMap: state.utils.printLanguagesMap,
    filterLabels: state.utils.filterLabels,
    isGetBrandsCompleted: state.utils.isGetBrandsCompleted,
    sortByRelevanceCriteria: state.utils.sortByRelevanceCriteria,
    isLoadedSortByRelevance: state.utils.isLoadedSortByRelevance,
    userProfile: state.user.userProfile,
    timeOnTraining: state.course.timeOnTraining,
    isTimeOnTrainingLoaded: state.course.isTimeOnTrainingLoaded,
    isLoadingSearchCourses: state.search.isLoadingSearchCourses,
    searchQuery: state.search.searchQuery,
    catalogueTitle: state.course.catalogueTitle,
    coursesMap: state.course.coursesMap,
    showCourseModal: state.course.showCourseModal,
    ecommerceEnable: state.user.ecommerceEnable,
    sectionL1: state.search.sectionL1,
    teacherUsernameValue: state.utils.filteredTeacher,
    isLoadingGetUserRelevantSession : state.utils.isLoadingGetUserRelevantSessions,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetSearch: () => dispatch(actions.resetSearch()),
    setTeacherUsername: () => dispatch(actions.setTeacherUsername()),
  };
};

//dispatch(actions.setTeacherUsername(teacherUsername));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FiltersV3));
